import React, { useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { nationalTaxServiceCredentialsDialogVar } from '../../apollo/reactive-variables/alerts';
import NationalTaxServiceCredentialsForm from '../forms/NationalTaxServiceCredentialsForm';
import LazyLoadImage from '../images/LazyLoadImage';
import TermsAndConditionsLink from '../links/TermsAndConditionsLink';

const NationalTaxServiceCredentialsDialog = () => {
  const { t } = useTranslation();
  const { open, autocomplete } = useReactiveVar(
    nationalTaxServiceCredentialsDialogVar,
  );

  const handleClose = useCallback(
    () => nationalTaxServiceCredentialsDialogVar({
      open: false,
      autocomplete: false,
    }),
    [nationalTaxServiceCredentialsDialogVar],
  );

  if (!open) {
    return null;
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth="md"
      PaperProps={{ sx: { borderRadius: 3 } }}
      fullWidth
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <Stack
        spacing={4}
        py={3}
        px={{ xs: 0, md: 6 }}
        alignItems="center"
        width={{ xs: '85%', md: '100%' }}
        mx="auto"
      >
        <LazyLoadImage
          src={t('National Tax Service logo')}
          alt="Sii Logo"
          width={{ mobile: 50, desktop: 60, lg: 70 }}
          height={{ mobile: 50, desktop: 60, lg: 70 }}
        />
        <Typography textAlign="center" variant="small_3" fontWeight={500}>
          Ingresa las credenciales del{' '}
          <Typography component="span" fontSize="inherit" fontWeight="bold">
            {t('National Tax Service')}
          </Typography>{' '}
          de tu empresa para acceder a los productos de Fingo
        </Typography>
        <Box width={{ xs: '85%', md: 400 }}>
          <NationalTaxServiceCredentialsForm autocomplete={autocomplete} />
        </Box>
        <TermsAndConditionsLink />
      </Stack>
    </Dialog>
  );
};

export default NationalTaxServiceCredentialsDialog;
